import {html, css} from 'lit'
import {graphQL} from "shared/graphQL"
import {Assets} from 'shared/assets'
import { TableBaseCellClick } from 'shared/tables/table-base-cell-click.component'
import { TableBase } from 'shared/tables/table-base.component'
import 'shared/tables/table-filter-screenshot-analysis.component'


class SmartScanTable extends TableBaseCellClick {

    connectedCallback() {
        super.connectedCallback()
        let shadowRoot = $('smart-scan-details')[0].shadowRoot
        $(shadowRoot).find('.stat-bar mss-label-value-box').on('click', {thiz: this}, this.filterClicked)
    }

    static styles = [
        TableBase.styles,
        css`
     [data-type="thumbnail"] {
        height: 100%;
        max-width: 150px;
     }

      [data-type="thumbnail"] a{
        height: unset;
      }

      [data-type="analysis"] {
        max-width: 50px;
        font-size: 24px;
        text-align: center;
      }
      [data-type="analysis"] svg {
        width: 40px;
        height: 40px;
      }
      [data-type="status"] {
        max-width: 110px;
      }
      [data-type="link"] {
        color: #438BDF;
        max-width: 150px;
        transition: color 0.2s;
        outline: none;
        text-decoration: none;
      }
      [data-type="link"] svg {
        position: relative;
        left: 8px;
        top: 1px;
        width: 8px;
      }
      .c-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%
      }

      .c-column {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }

      .c-column span {

      }

      [data-type="thumbnail"] span {
        display: block;
        height: 100px;
        padding-top: 10px;
      }

      [data-type="thumbnail"] span img {
        height: 80px;
      }

      header {
        display: flex;
        justify-content: flex-start;
      }

      .thumbnail {
        height: 100%;
      }
    `,
    ];


    constructor() {
        super()
        this.dataName = 'Smart Scans'
        this.columns = [
            {key: 'thumbnail', title: '', display: 'always-left'},
            {key: 'analysis', title: '', display: 'always-left'},
            {key: 'player', title: 'Player', sortable: 'player_name'},
            {key: 'scan_date', title: 'Scan Date', sortable: 'date'},
            {key: 'link', title: '', display: 'always-right'},
        ];
        this.orderBy = 'date'   // default
        this.orderType = 'descending'   // default

        this.paginationEnabled = true
        this.paginationOptions.dataName = 'Smart Scans'
        this.searchEnabled = true
        this.filterSections = html`
            <table-filter-screenshot-analysis></table-filter-screenshot-analysis>
        `;
        this.settingsEnabled = true
    }

    getData() {
        let graphqlQuery = `
      query screenshots($input : ScreenshotFilter, $filteringOnly: ScreenshotFilter) {
        screenshotCount(filters: $filteringOnly)
        screenshots(filters: $input) {
          id
          capturedAt
          analysisStatus
          analysisComment
          imageSmall: image(size: SMALL)
          imageLarge: image(size: LARGE)
          player {
            id
            name
            online
          }
        }
      }
    `;

        let graphqlVariables = {
            input: {
                pager: {
                    pageSize: this.pageSize,
                    page: this.page - 1,
                },
                newestRecordsOnly: true,
                forAccountId: graphQL.accountId(),
            },
            filteringOnly: {
                newestRecordsOnly: true,
                forAccountId: graphQL.accountId(),
            }
        };

        if (this.orderBy === 'date') {
            graphqlVariables.input['orderByCapturedAt'] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
        } else if (this.orderBy === 'player_name') {
            graphqlVariables.input['orderByPlayerName'] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
        } else if (this.orderBy === 'status') {
            graphqlVariables.input['orderByPlayerOnlineStatus'] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
        }

        if (this.searchValue) {
            graphqlVariables.input['playerNameLike'] = this.searchValue
        }
        let filtering = {
            whereAnalysisStatus: ['BROKEN', 'SEMI_BROKEN']
        }
        let val = this.filterValues
        if (val) {
            let analysis = val['table-filter-screenshot-analysis']
            if (analysis) {
                filtering['whereAnalysisStatus'] = analysis
            }
        }

        // Put filtering in both input and filteringOnly
        for (const filter in filtering) {
            graphqlVariables.input[filter] = filtering[filter]
            graphqlVariables.filteringOnly[filter] = filtering[filter]
        }

        graphQL.query({
            query: graphqlQuery,
            variables: graphqlVariables,
            success: (response) => {
                this.totalCount = response.data.screenshotCount
                this.data = response.data.screenshots
            },
            error: (message) => {
                this.data = []
                console.log(`graphQL screenshots query error: ${message}`)
            },
        });
    }


    cellValue(colKey, item) {
        let value = html``;

        if (colKey === 'thumbnail') {
            value = html`
                <img class="thumbnail" src="${item.imageSmall}"/>`;
        } else if (colKey === 'analysis') {
            if (item.analysisStatus === 'NORMAL') {
                value = html``
            } else if (item.analysisStatus === 'SEMI_BROKEN') {
                value = html`${Assets.inlineSvg('screenshot/icon-semi-broken.svg')}`
            } else if (item.analysisStatus === 'BROKEN') {
                value = html`${Assets.inlineSvg('screenshot/icon-broken.svg')}`
            } else {
                value = html`
                    <div title="Not Analyzed" style="width: 100%; height: 100%;">&nbsp;</div>`
            }

        } else if (colKey === 'player') {
            value = html`${item.player.name}`;

        } else if (colKey === 'scan_date') {
            value = html`${Util.formatDateTime(item.capturedAt)}`

        } else if (colKey === 'status') {
            let cls = item.player.online ? 'online' : 'offline'
            let text = item.player.online ? 'Online' : 'Offline'
            value = html`<span class="${cls}">${text}</span>`

        } else if (colKey === 'link') {
            value = html`Go to Player ${Assets.inlineSvg('caret.svg')}`
        }
        return value;
    }


    cellUrl(key, item) {
        if (key === 'link') {
            return Routes.player_path(item.player.id)
        }
        return null
    }


    updated(changedProperties) {
        let rows = this.renderRoot.querySelectorAll('.c-row')
        for (const row of rows) {
            row.addEventListener('click', this.rowClick)
        }
    }

    setFilter(filter) {
        this.filterValues = {'table-filter-screenshot-analysis': [filter]}
    }

    addCssActiveClass(selector) {
        $($(selector.shadowRoot).children()[0]).addClass('active')
    }

    removeCssActiveClass(selector) {
        $($(selector.shadowRoot).children()[0]).removeClass('active')
    }

    changeSelectionOfFilters(selector, filterValues) {
        $($($(selector).find('table-filter-screenshot-analysis'))[0].shadowRoot).find('mss-label').each(function (index, mssCheckBox) {
            let selector = $(mssCheckBox).find('mss-checkbox')[0]
            let value = selector.value
            let check = false
            if (filterValues && filterValues['table-filter-screenshot-analysis'] && filterValues['table-filter-screenshot-analysis'].indexOf(value) >= 0) {
                check = true
            }
            selector.checked = check
        });
    }


    filterClicked(data) {
        let mssLabelValueBox = $(data.target.closest('mss-label-value-box'))
        let filter = mssLabelValueBox.attr('value')
        let filterValues = data.data.thiz.filterValues
        if (filter == 'BROKEN' || filter == 'SEMI_BROKEN') {
            let selector = $(data.data.thiz.shadowRoot).find('.table-base table-filter')[0]
            if (filterValues) {
                if (filterValues['table-filter-screenshot-analysis']) {
                    let index = filterValues['table-filter-screenshot-analysis'].indexOf(filter)
                    if (index >= 0) {
                        filterValues['table-filter-screenshot-analysis'].splice(index, 1)
                        if (filterValues['table-filter-screenshot-analysis'].length == 0) {
                            delete filterValues['table-filter-screenshot-analysis']
                        }
                        data.data.thiz.getData()
                        data.data.thiz.removeCssActiveClass(selector)
                        data.data.thiz.changeSelectionOfFilters(selector)
                        return
                    }
                }
            }
            data.data.thiz.setFilter(filter)
            data.data.thiz.getData()
            data.data.thiz.addCssActiveClass(selector)
            data.data.thiz.changeSelectionOfFilters(selector, data.data.thiz.filterValues)
        }
    }

    cellClick(key, item, event) {
        if (key === 'thumbnail' || key === 'analysis') {
            let element = document.createElement("smart-scan-slideshow")
            element.data = this.data
            element.index = this._idToIndex(item.id)
            let listener = (event) => {
                element.removeEventListener('close', listener)
                document.body.removeChild(element)
            }
            element.addEventListener('close', listener)
            document.body.appendChild(element)
        }
    }


    _idToIndex(id) {
        for (let index = 0; index < this.data.length; index++) {
            if (this.data[index].id === id) {
                return index
            }
        }
        return null
    }


    defaultTableSettings() {
        return {
            desktop: {
                visibleColumns: [
                    'player',
                    'scan_date',
                ]
            },
            tablet: {
                visibleColumns: [
                    'player',
                    'scan_date',
                ]
            },
            mobile: {
                visibleColumns: [
                    'player',
                    'scan_date',
                ]
            },
        }
    }
}

customElements.define('smart-scan-table', SmartScanTable)
