import {LitElement, html, css} from 'lit';
import {graphQL} from "shared/graphQL"

export class RolesIndex extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .mar30t {
      margin-top: 30px;
    }

    .mar10b {
      margin-bottom: 10px;
    }

    .stat-bar {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
    }

    .stat-bar .stat.floating-label label, .stat-bar .floating-label.stat-fill label {
      position: absolute;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      padding: 0;
      top: -13px;
      left: 0;
      right: 0;
      height: auto;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #5B5B5B !important;
    }

    .stat-bar .stat.floating-label:last-child, .stat-bar .floating-label.stat-fill:last-child {
      margin-right: 0;
    }
    .stat-bar .stat.floating-label, .stat-bar .floating-label.stat-fill {
      display: block;
      position: relative;
      margin-right: 20px;
    }
    .stat-bar .stat, .stat-bar .stat-fill {
      flex: 1;
      background-color: white;
      border: 2px solid #979797;
      border-radius: 8px;
      max-width: 345px;
      display: flex;
      height: 40px;
      align-items: center;
    }
  
    .stat-bar .stat label, .stat-bar .stat-fill label {
      font-size: 16px;
      color: #5B5B5B;
      padding: 0 10px;
      flex: 1;
    }
    .stat-bar .stat label, .stat-bar .stat-fill label, .stat-bar .stat .value, .stat-bar .stat-fill .value {
        display: flex;
        height: 40px;
        align-items: center;
    }
    .stat-bar .stat.floating-label label, .stat-bar .floating-label.stat-fill label {
      position: absolute;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      padding: 0;
      top: -13px;
      left: 0;
      right: 0;
      height: auto;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #5B5B5B !important;
    }
    .stat-bar .stat .value, .stat-bar .stat-fill .value {
      flex: none;
      padding: 0 20px;
      font-size: 24px;
      border-left: 2px solid #979797;
      color: #5B5B5B;
    }
    .stat-bar .stat.floating-label .value, .stat-bar .floating-label.stat-fill .value {
      border: none;
      height: 36px;
      align-items: center;
      justify-content: space-around;
    }
  `;

  static properties = {
    data: {state: true},
    missingImageUrl: {},
    orgid: {},
  };

  constructor() {
    super()
    this.data = {}
  }

  update() {
    super.update();
  }

  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }

  getData() {
    let query = `
    query {
      userCountsByRole(organizationId:${this.orgid}) { # use organizationId in order to fix this
        accountAdmin
        contentContributor
        employee
        locationAdmin
        organizationAdmin
        superAdmin
      }
    }
    `;
    
    graphQL.query({
      query: query,
      success: (response) => {
        this.data = response.data.userCountsByRole
      },
      error: (message) => {
        console.log(`Error getting user role count: ${message}`)
      },
    });
  }

  render() {
    let roles = [];
    // convert symbol to readable form
    for(let key of Object.keys(this.data)) {
      let value = this.data[key];
      if (value > 0) {
        const tmpstr = key.replace(/([A-Z])/g, " $1");
        const label = tmpstr.charAt(0).toUpperCase() + tmpstr.slice(1);

        roles.push(html`<div class="stat floating-label"><label>${label}
          </label><div class="value">${value}</div></div>`);
      }
      console.log("name=" + key + ", value=" + value);
    }
    return html`
    <div class="stat-bar mar30t mar10b">
    ${roles}
    </div>
    `;
  }
}
customElements.define('roles-index', RolesIndex)
