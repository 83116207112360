/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
require("pikaday")

require("screenshots/smart-scan-details.component.js")
require("screenshots/smart-scan-summary.component.js")

require("players/players-index.component")
require("players/player-folderless-index.component")

require("locations/locations-index.component")

require("users/users-table.component")
require("users/users-index.component")
require("users/roles-index.component")

require("integrations/background-jobs-table.component")
require("integrations/integrations-table.component")
require("integrations/integration-edit.component")
require("integrations/integration-new.component")
require("integrations/integration-show.component")
require("integrations/integration-modal.component")

require("tags/tags-edit.component")
require("shared/tables/table-base-cell-click.component")
require("shared/tables/table-pagination.component")
require("shared/tables/table-filter-attributes.component")

require("organizations/organizations-table.component")

require("shared/foundation/mss-timezone.component.js")
require("shared/foundation/mss-card.component.js")
require("shared/foundation/mss-button.component.js")
require("shared/foundation/mss-checkbox.component")
require("shared/foundation/mss-label.component")
require('shared/foundation/base-color-pill.component')
require('shared/foundation/focus-color-pill.component')
require('shared/foundation/recurring-schedule.component')
require('shared/foundation/capitalize')
require('shared/foundation/contextualized-select.component')
require('shared/foundation/pikaday.component')
require('shared/foundation/multi-selector.component')
require('shared/foundation/standard-repeating-options.component')
require('shared/CRONExpression')
require('shared/modalManager')
require('shared/cron-parser')
require('shared/foundation/cron-field.component')
require('shared/foundation/recurring-schedule-sentence.component')

require('content/content-template/mini-navbar.component')
require('content/content-template/content-location.component')
require('shared/dialogHelpers')
