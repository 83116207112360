import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets'
import 'shared/tables/table-filter.component'
import 'shared/tables/table-search.component'
import 'shared/tables/table-settings.component'
import 'shared/tables/table-pagination.component'
import 'shared/tables/table-actions.component'
import 'shared/tables/table-bulk-actions.component'
import {graphQL} from "../graphQL";


export class TableBase extends LitElement {
  static styles = css`

    *, *:before, *:after {
      box-sizing: border-box;
    }
    .table-base {
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, .5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      padding: 0 17px;
      font-size: 15px;
      width: fit-content;
    }

    .table-toolbar {
      display: flex;
      justify-content: space-between;
      padding-top: 26px;
      padding-bottom: 20px;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 60px;
      border-bottom: 1px solid #e0e0e0;
    }

    .toolbar-right {
      display: flex;
    }

    .c-column {
      height: 60px;
      white-space: nowrap;
      overflow: hidden;
    }
    .c-column > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .c-row {
      height: 60px;
      transition: background-color 0.2s ease-in-out;
    }
    .c-row, header, .c-row:last-child, header:last-child {
      border-bottom: 1px solid #e0e0e0;
    }
    .c-row:hover {
      background-color: #f2f2f2;
    }
    .c-row mss-checkbox, .c-row mss-favorite-toggle {
      visibility: hidden;
    }
    .c-row mss-checkbox[checked], .c-row mss-favorite-toggle[checked] {
      visibility: visible;
    }
    .c-row:hover mss-checkbox, .c-row:hover mss-favorite-toggle {
      visibility: visible;
    }

    header {
      height: 47px;
    }
    header .c-column {
      font-size: 15px;
      font-weight: bold;
      cursor: default;
    }

    header .c-column[data-sortable] {
      cursor: pointer;
    }

    header .sorted {
      color: #438BDF;
    }
    header [data-sortable] {
      position: relative;
      cursor: pointer;
    }
    header [data-sortable] > span {
      position: relative;
      padding-right: 24px;
    }

    .caret-icon svg {
      height: 7px;
      width: 14px;
      transition: transform 0.3s
    }
    .caret-icon svg g {
      fill: #438bdf;
    }

    thead tr th[data-sortable] .mark {
      display: none;
    }

    thead tr th[data-sortable].descending.sorted .mark {
      display: inline-block;
    }

    thead tr th[data-sortable].descending.sorted .mark svg {
      transform: rotate(0deg);
    }

    thead tr th[data-sortable].ascending.sorted .mark {
      display: inline-block;
    }

    thead tr th[data-sortable].ascending.sorted .mark svg {
      transform: rotate(180deg);
    }

    .empty-table {
      width: 100%;
      height: 57px;
      background-color: #f3f3f3;
      padding-top: 18px;
      text-align: center;
      border-bottom: 1px solid #e0e0e0;
    }

    .c-row:hover table-actions {
      border-color: #979797;
    }
    td[data-type=icon], .c-column[data-type=icon] {
      min-width: 52px !important;
      max-width: 52px !important;
      width: 52px !important;
    }

    .c-column[data-type=icon] > span {
      overflow: visible;
    }

    td[data-type=bulk_action], .c-column[data-type=bulk_action] {
      min-width: 32px !important;
      max-width: 32px !important;
      width: 32px !important;
    }
    td[data-type=bulk_action] a {
      min-width: 32px !important;
      max-width: 32px !important;
      width: 32px !important;
    }

    td[data-type=actions], .c-column[data-type=actions] {
      min-width: 48px !important;
      max-width: 48px !important;
      width: 48px !important;
      overflow: visible;
    }
    td[data-type=favorite], .c-column[data-type=favorite] {
      min-width: 48px !important;
      max-width: 48px !important;
      width: 48px !important;
      }

    .c-column[data-type=favorite] > span {
      padding: 2px;
    }

    a {
      color: #5B5B5B;
      text-decoration: none;
    }

    .c-row:hover .link a {
      color: #438BDF;
      text-decoration: underline;
    }

    .c-column a {
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      height: 60px;
      width: 100%;
    }

    .c-column a span {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .table-base-body *{
      cursor: pointer !important;
    }

    .table-base-body.currentlyDragging *{
      cursor: move !important;
    }

    .table-base-body.currentlyDragging .c-column[data-type="bulk_action"] span mss-checkbox {
      opacity: 0;
    }
    .table-base-body.currentlyDragging .c-column[data-type="bulk_action"] span mss-checkbox[checked] {
      opacity: 1;
    }

    body{
      margin:0px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%
    }

    thead tr {
      border-bottom: 1px solid rgb(224, 224, 224);
    }

    table th {
      position: relative;
      min-width: 32px !important;
      width: 100%;
    }

    table th .th-container {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    table th .th-container div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    table th .grab {
      display: inline-block;
      min-width: 10px;
      width: 20%;
      height: 100%;
      cursor: col-resize;
    }

    th span {
      cursor: pointer;
    }

    td {
      padding-left: 2px;
      padding-right: 2px;
      overflow: hidden;
      height: 60px;
    }

    td div {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
    }

    td span {
      display: inine-block;
      overflow: hidden;
    }

    .resizer {
        /* Displayed at the right side of column */
        position: absolute;
        top: 0;
        right: 0;
        width: 5px;
        height: 22px;
        padding-left: 5px;

        user-select: none;
    }

    th:hover .resizer {
      border-right: 1px solid #438bdf;
    }

    .resizer:hover {
      border-right: 1px solid #438bdf;
    }

    .c-col > span {
      white-space: nowrap;
    }
    .no-link {
      cursor: default;
      pointer-events: none;
    }
    .inline-block{
      display: inline-block;
    }

    .no-overflow {
      display: inline-block;
      white-space: nowrap;
      position: relative; /* must be relative */
      width:100%; /* fit to table cell width */
      margin-right:-1000px; /* technically this is a less than zero width object */
    }
  `;

  static properties = {
    data: {state: true},
    totalCount: {state: true}
  };


  /**
   * getter to unify the common query to get the checkboxes
   */
  get #bulkActionCheckboxes() {
    return this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox');
  }

  /**
   * Property to determine in any of the bulk action checkboxes are checked.
   *
   * Left public to allow a clean way to check this without accessing the shadowDOM
   */
  get anyChecked() {
    for (const e of this.#bulkActionCheckboxes) {
      if (e.checked) {
        return true;
      }
    }

    return false;
  }


  constructor() {
    super()
    this.day = 0
    this.data = null
    this.route = ''
    this.showDetails = true;
    this.name = this.constructor.name
    this.columns = []
    this.orderBy = ''
    this.orderType = 'ascending'   // ascending or descending

    this.paginationEnabled = true
    this.page = 1
    this.totalCount = null

    this.filterSections = null
    this.bulkActionsMenu = null
    this.bulkActionValues = []
    this.paginationOptions = {
      dataName: '',
      pageSizeOptions: [10, 25, 50, 100, 200],
      pageSizeDefault: 25,
      navMax: 5,
    }
    this.x = 0;
    this.w = 0;
    this.moving = false;
    this.shouldClear = false;
  }

  fetchCacheName() {
    return this.name
  }


  connectedCallback() {
    super.connectedCallback()
    if (this.getData) {
      graphQL.ready(() => this.getData())
    }
  }


  render() {
    this.actionList = {}
    // this.actionsFlag = false
    // for (const col of this.columns) {
    //   if (col.key === 'actions') this.actionsFlag = true;
    // }

    // let tableClasses = this.actionsFlag ? 'actions' : ''
    // if (this.tableClasses) {
    //   tableClasses = `${tableClasses} ${this.tableClasses}`
    // }

    let toolbar = html``;
    let footer = html``;

    if (this.filterSections || this.bulkActions || this.searchEnabled) {
      toolbar = html`
          <div class="table-toolbar">
              ${this.toolbar()}
          </div>`;
    }

    if (this.paginationEnabled) {
      footer = html`
          <footer>
              ${this.footer()}
          </footer>`;
    }

    return html`
      <mss-player-move-dialog orgId="${graphQL.organizationId()}"></mss-player-move-dialog>
      <div class="table-base">
        <div class="table-toolbar">
          ${this.toolbar()}
        </div>
        <table>
          <thead>
            <tr>
              ${this.tableHeader()}
            </tr>
          </thead>
          <tbody class="table-base-body">
            ${this.tableBody()}
          </tbody>
        </table>
        <footer>
          ${this.footer()}
        </footer>
      </div>
    `;
  }

  updated() {
    for (const box of this.#bulkActionCheckboxes) {
      box.addEventListener('change', (event) => {
        this.bulkActionCheckboxChange(event)
      })
    }
    this.afterUpdated();
  }

  afterUpdated() {
  }

  generateBulkActionMenu() {
    if (this.data === null || this.data === undefined)
      return false;
    else
      return this.data.length > 0 ? this.bulkActionsMenu : false ;
  }


  toolbar() {
    let left = []
    if (this.filterSections) {
      left.push(html`
        <table-filter @change=${this.filterChange}>${this.filterSections}</table-filter>
      `)
    }

    if (this.generateBulkActionMenu()) {
      left.push(html`
        <table-bulk-actions id="bulk_actions"
              @selectAll=${this.bulkActionSelectAll}
              @deselectAll=${this.bulkActionDeselectAll}>
          ${this.bulkActionsMenu}
        </table-bulk-actions>
      `)
    }

    let right = []
    if (this.searchEnabled) {
      right.push(html`
          <table-search @change=${this.searchChange}></table-search>`);
    }
    if (this.settingsEnabled) {
      right.push(html`
          <table-settings
                  @save=${this.settingsSave}
                  @close=${this.settingsClose}
                  .settings=${this.settings()}
                  .orgid=${this.orgid}
                  .accountid=${this.accountid}
                  .defaultSettings=${this.defaultSettings()}
          >
          </table-settings>
      `);
    }

    return html`
      <div class="toolbar-left">${left}</div>
      <div class="toolbar-right">${right}</div>
    `;
  }

  filterChange(event) {
    this.filterValues = event.currentTarget.value
    this.updateTable()
  }


  setFilterValues(values) {
    let filter = this.shadowRoot.querySelector('table-filter')
    if (filter) {
      filter.setValues(values)
    }
  }


  bulkActionSelectAll(event) {
    for (const box of this.#bulkActionCheckboxes) {
      box.checked = true
    }
    this.dispatchEvent(new Event('tableBulkActionsAdded', {bubbles: true, composed: true}));
    this.updateBulkActionValues()
  }


  bulkActionDeselectAll(event) {
    for (const box of this.#bulkActionCheckboxes) {
      box.checked = false
    }
    this.dispatchEvent(new Event('tableBulkActionsEmpty', {bubbles: true, composed: true}));
    this.updateBulkActionValues()
  }


  bulkActionCheckboxChange(event) {
    const bulkActions = this.shadowRoot.querySelector('table-bulk-actions')
    if (event.currentTarget.checked) {
      this.dispatchEvent(new Event('tableBulkActionsAdded', {bubbles: true, composed: true}));
      bulkActions.open = true
    } else {
      const anyChecked = this.anyChecked;
      this.dispatchEvent(new Event(
        anyChecked ? 'tableBulkActionsRemoved' : 'tableBulkActionsEmpty',
        {bubbles: true, composed: true})
      );
      // If any of the boxes are checked
      // then the bulk action menu should be open.
      bulkActions.open = anyChecked;
    }

    this.updateBulkActionValues()
  }


  updateBulkActionValues() {
    let values = []
    for (const box of this.#bulkActionCheckboxes) {
      if (box.checked) {
        values.push(box.value)
      }
    }
    this.bulkActionValues = values
  }


  searchChange(event) {
    this.searchValue = event.currentTarget.value
    this.updateTable()
  }


  tableHeader() {
    let header = []
    for (const setting of this.settings()) {
      let col = this.getColumnByKey(setting.key)
      if (!col || !setting.visible) continue
      let classVal = 'c-column'
      classVal += this.columnClasses(col)
      if (col.sortable) {
        if (col.sortable === this.orderBy && (!this.orderKey || this.orderKey === col.key)) {
          classVal += ' sorted ' + this.orderType
        }
        header.push(html`
          <th id="res${col.key}"
              data-type="${col.key}"
              class="${classVal}"
              data-sortable="${col.sortable}"
              style="width: ${setting.width || '100%'}"
              @mousedown="${this.columnHeaderClick}">
            <div class="th-container">
              <div>
                ${col.hide_in_column ? '' : col.title}
                <span class="mark caret-icon">${Assets.inlineSvg('caret-solid.svg')}</span>
              </div>
              <span class="grab" @mousedown='${this._resMouseDown}' @click='${this._resClick}'>&nbsp;</span>
            </div>
          </th>
        `);
      } else {
        if (['icon', 'bulk_action', 'favorite', 'actions', 'imageUrl'].includes(col.key)) {
          header.push(html`
            <th id="res${col.key}alt" data-type="${col.key}" class="${classVal}">
              ${col.hide_in_column ? '' : col.title}
            </th>
          `);
        } else {
          header.push(html`
            <th id="res${col.key}"
                data-type="${col.key}"
                class="${classVal}"
                style="width: ${setting.width || '100%'}"
                @mousedown="${this.columnHeaderClick}">
              <div class="th-container">
                <div>
                  ${col.hide_in_column ? '' : col.title}
                </div>
                <span class="grab" @mousedown='${this._resMouseDown}' @click='${this._resClick}'>&nbsp;</span>
              </div>
            </th>
          `);
        }
      }
    }
    return header
  }

  _resClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  _resMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const col = e.currentTarget.parentElement.parentElement;
    const columns = e.currentTarget.parentElement.parentElement.parentElement.children;
    const startX = e.pageX;
    const startW = e.currentTarget.parentElement.clientWidth;
    const moveHandler = (event) => { this._resMouseMove(col, event.pageX, startX, startW) };
    const upHandler = (event) => {
      event.preventDefault();
      event.stopPropagation()
      document.removeEventListener('mousemove', moveHandler);
      this.removeEventListener('mouseup', upHandler);
      this.saveColWidths(columns);
    }
    document.addEventListener('mousemove', moveHandler);
    this.addEventListener('mouseup', upHandler);
  }

  _resMouseMove = (col, pageX, startX, w) => {
    var newWidth = w + (pageX - startX);
    col.style.width = newWidth < 25 ? '25px' : `${newWidth}px`;
    col.style.minWidth = '0px';
    col.style.maxWidth = '1000px';
  }

  saveColWidths(columns) {
    var settings = this.settings();
    for (const col of settings) {
      if(!col.visible) { continue; }// !!!
      let column = this.shadowRoot.querySelector(`thead th[data-type=${col.key}]`);
      let cwidth = column.clientWidth < 25 ? '25px' : `${column.clientWidth}px`;
      column.style.width = cwidth;
      col.width = cwidth;
    }
    this.saveSettings(settings);
  }

  columnHeaderClick(event) {
    let heading = event.currentTarget
    let asc = heading.classList.contains('ascending')
    // clear out current sort
    let list = this.shadowRoot.querySelectorAll(`header [data-sortable="${this.orderBy}"]`)
    for (const head of list) {
      head.classList.remove('sorted', 'ascending', 'descending')
    }
    // set new sort
    heading.classList.add('sorted')
    this.orderBy = heading.getAttribute('data-sortable')
    this.orderKey = heading.getAttribute('data-type')
    if (asc) {
      heading.classList.remove('ascending')
      this.orderType = 'descending'
      heading.classList.add(this.orderType)
    } else {
      heading.classList.remove('descending')
      this.orderType = 'ascending'
      heading.classList.add(this.orderType)
    }
    this.updateTable()
  }


  tableBody() {
    let body = []
    if (!this.data) {
      body.push(html`
        <tr>
          <td colspan="100%" class="empty-table">
            <em>Retrieving Data</em>
          </td>
        </tr>
      `);
    } else if (this.dataError) {
      body.push(html`
        <tr>
          <td colspan="100%" class="empty-table error">
            <em>Error Retrieving Data</em>
          </td>
        </tr>
      `);
    } else if (this.data.length > 0) {
      let settings = this.settings();
      let firstKey = this.getFirstTextColumn(settings);
      for (let index = 0; index < this.data.length; index++) {
        let item = this.data[index]
        let row = []
        for (const setting of settings) {
          let col = this.getColumnByKey(setting.key)
          if (!col || !setting.visible) continue
          let classes = this.columnClasses(col)
          let value = this.cellValue(col.key, item)
          let cellUrl = this.cellUrl(col.key, item)
          let route = this.route
          if (typeof this.route === 'object') {
            route = item.recordType === 'PlayerFolder' ? this.route.folder : this.route.item
          }
          if (cellUrl) {
            let link = []
            if (Array.isArray(value)) {
              if ((value.length === 2) && (col.key !== 'attributes')) {
                link.push(html`<div>
                                  <span class="user_name">${value[0] != undefined ? value[0] : html`--`}</span><br />
                                  <span class="user_position">${value[1] != undefined ? value[1] : html``}</span>
                                </div>`);
              } else if (col.key === 'attributes') {
                for (const att of value) {
                  link.push(html`<span class="attribute_pill">${att}</span>`)
                }
              }
            } else {
              if (col.circular_image) {
                link.push(html`
                        ${value}`)
                if (item.contents && item.contents.length > 0) {
                  link.push(html`
                      <div class="user-in-content_badge">
                          <i class="far fa-eye"></i>
                      </div>`)
                }
              } else {
                link.push(html`${value}`)
              }
            }
            row.push(html`<td class="c-column"
                              title="${col.key != 'bulk_action' && col.key != 'imageUrl' ? this.trimComma(value) : ''}">
                            <div class="no-overflow">
                              <a class="${classes} ${this.showLink(col.key)}"
                                data-type="${col.key}"
                                href="${cellUrl}">
                                <span>
                                    ${link}
                                </span>
                              </a>
                            </div>
                          </td>`);
          } else if (col.key === 'actions' && Array.isArray(value)) {
            let actionComponent = html``
            if (!this.rowIsDisabled(item)) {
              actionComponent = html`
                  <table-actions style="z-index: 1;" .actions=${value}
                                 @open=${this.actionsOpen}
                                 @close=${this.actionsClose}></table-actions>
              `;
            }
            row.push(html`
              <td class="c-column ${classes}" data-type="${col.key}" data-index="${index}" title=''>
                ${actionComponent}
              </td>
            `)
          } else {
            let key = (col.key === 'favorite') && this.rowIsDisabled(item) ? 'disabled' : col.key
            if (typeof value === 'string') {
              if(col.key === firstKey) {
                row.push(
                  html`
                    <td class="c-column link ${classes}" data-type="${key}" data-index="${index}" title="${value}">
                      <div class="no-overflow">
                        <a href="${route.replace(':id', item.id)}" class="${this.showLink()}">
                          <span>${value}</span>
                        </a>
                      </div>
                    </td>
                `);
              }else{
                row.push(
                  html`
                    <td class="c-column ${classes}" data-type="${key}" data-index="${index}" title="${value}">
                      <div class="no-overflow">
                        <a href="${route.replace(':id', item.id)}" class="${this.showLink()}">
                          <span>
                            ${value}
                          </span>
                        </a>
                      </div>
                    </td>`
                );
              }
            } else {
              let objData = false
              let data = value
              let title = value
              if (value?.hasOwnProperty('title') && value?.hasOwnProperty('data')) {
                data = value.data
                title = value.title
                objData = true
              }
              row.push(html`
                <td class="c-column ${classes}" data-type="${key}" data-index="${index}">
                  <div class="inline-block">
                    <a href="${route.replace(':id', item.id)}" title="${objData ? title : ''}" class="${this.showLink()}">
                        ${value === undefined ? html`&nbsp;` : data}
                    </a>
                  </div>
                </td>
              `);
            }
          }
        }
       body.push(html`
          <tr data-id="${item.id}" class="c-row ${this.rowClass(item)}">
              ${row}
          </tr>
        `)
      }
    } else {
      if (this.page !== 1) {
        this.page = 1
      }
      body.push(html`
        <td class="empty-table error" colspan="100%">
          <em>No ${this.dataName} found</em>
        </td>
      `);
    }
    return body
  }

  getFirstTextColumn(columns) {
    for (const i of columns) {
      if (i.key !== 'icon' && i.key !== 'bulk_action') {
        return i.key;
      }
    }
    return 'name';
  }

  actionsOpen(event) {
    const tableRegEx = /.*-[index|table]/i;
    this.closeAllActions(this.shadowRoot.querySelectorAll('table-actions') || []);
    let upHandler = (event) => {
      document.removeEventListener('mouseup', upHandler);
      if(!tableRegEx.exec(event.target.nodeName)) {
        this.actionsClose(this.shadowRoot.querySelectorAll('table-actions'));
      }
    }
    document.addEventListener('mouseup', upHandler);
  }

  actionsClose(event) {
    this.closeAllActions(this.shadowRoot.querySelectorAll('table-actions') || []);
  }

  closeAllActions(actions) {
    for (const action of actions) {
      action.open = false
    }
  }

  cellClickFrontend(event) {
    let key = event.currentTarget.getAttribute('data-type')
    let index = event.currentTarget.getAttribute('data-index')
    this.cellClick(key, this.data[index], event)
  }

  trimComma(str) {
    if(str === null) { return ''; }
    if(typeof str === 'object') {
      return str.join(', ');
    }
    if(typeof str === 'string' && str.endsWith(',')) {
      return str.slice(0, -1);
    }
    return str;
  }

  footer() {
    if (!this.paginationEnabled) return ''

    return html`
      <table-pagination
          .page=${this.page}
          .pageSize=${this.pageSize}
          .totalCount=${this.totalCount}
          .options=${this.paginationOptions}
          @change=${this.paginationChange}
      ></table-pagination>
    `;
  }


  paginationChange(event) {
    this.page = event.currentTarget.page
    this.pageSize = event.currentTarget.pageSize
    this.updateTable()
  }


  columnClasses(column) {
    return column.class ? ' ' + column.class : ''
  }


  updateTable() {
    let pagination = this.querySelector('table-pagination')
    if (pagination) {
      pagination.page = 1
      this.page = 1
    }
    if (this.getData) {
      this.getData()
    } else {
      this.requestUpdate()
    }
  }


  get pageSize() {
    let val = localStorage.getItem(this.fetchCacheName() + '_pageSize')
    if (val === 'null' || val === undefined || val === null || val === '') {
      val = this.paginationOptions.pageSizeDefault;
      this.pageSize = val
    } else {
      val = parseInt(val)
      if (isNaN(val) || val <= 0) {
        val = this.paginationOptions.pageSizeDefault;
        this.pageSize = val
      }
    }
    return val
  }


  set pageSize(val) {
    localStorage.setItem(`${this.fetchCacheName()}_pageSize`, val)
  }


  settings() {
    let settings = this.getStoredSettings()
    if (settings) {
      return settings
    }
    // No settings stored for this table.
    // Use the default settings.
    return this.defaultSettings()
  }


  defaultSettings() {
    // Build default table setting.
    let settings = []
    // Make a rough determination of the device type based on screen size.
    let screenType = 'desktop'
    const width = screen.width
    if (width < 480) {
      screenType = 'mobile'
    } else if (width < 768) {
      screenType = 'tablet'
    }

    let defaults = this.defaultTableSettings() || {}
    let platform = defaults[screenType] || {}
    let visibleColumns = platform.visibleColumns

    for (const col of this.columns) {
      if ((col.key == 'account' && (this.accountid || this.locationid)) || (col.key == 'organization' && (this.orgid || this.locationid))) {
      } else {
        let visible = true
        if (visibleColumns) {
          visible = visibleColumns.includes(col.key) ||
            (col.display && col.display.indexOf('always') === 0)
        }
        settings.push({
          key: col.key,
          title: col.title || (col.key === 'bulk_action' ? 'select' : col.key),
          visible: visible,
          display: col.display,
        })
      }
    }
    return settings
  }


  saveSettings(settings) {
    let key = `${this.fetchCacheName()}_tableSettings${this.columns.map(x => x.title + x.key).join('')}`
    if (!Array.isArray(settings)) settings = null
    let str = undefined
    if (settings) {
      for (const setting of settings) {
        let column = this.shadowRoot.querySelector(`[data-type="${setting.key}"]`);
        if(column) {
          column.style.width = setting.width ? `${setting.width}px` : '100%';
          console.log(`${setting.key} final width: `, column.style.width);
        }
      }
      try {
        str = JSON.stringify(settings)
        if (str) {
          localStorage.setItem(key, str)
          return true
        }
      } catch (e) {
        str = undefined
      }
    }
    // Something when wrong, remove stored value.
    localStorage.removeItem(key)
    return false
  }


  getStoredSettings() {
    let key = `${this.fetchCacheName()}_tableSettings${this.columns.map(x => x.title + x.key).join('')}`
    let raw = localStorage.getItem(key)
    if (raw === 'null' || raw === undefined || raw === null || raw === '') return null
    let val = []
    try {
      val = JSON.parse(raw)
    } catch (e) {
      return null
    }
    // Do a simple check on the retrieved settings.
    if (!Array.isArray(val)) return null
    for (const item of val) {
      if (!item.key) return null
    }
    return val
  }


  settingsSave(event) {
    let settings = event.currentTarget.settings;
    settings.forEach(v=>{delete v.width});
    this.saveSettings(settings);
    this.requestUpdate();
  }

  flagColumnClear(event) {
    this.shouldClear = true;
  }

  settingsClose(event) {
    event.stopPropagation()
  }

  getColumnByKey(key) {
    for (const col of this.columns) {
      if (col.key === key) {
        return col
      }
    }
    return null
  }

  showLink(colKey = '') {
    if(colKey == 'bulk_action') { return ''; }
    if (!this.showDetails) {
      return 'no-link';
    }
  }

  //-------------------------------------------------------------------------
  //-------------------------------------------------------------------------
  cellValue(colKey, item) {
    return item[colKey]
  }

  //-------------------------------------------------------------------------
  cellUrl(colKey, item) {
    return null
  }

  //-------------------------------------------------------------------------
  rowIsDisabled(item) {
    return false
  }

  //-------------------------------------------------------------------------
  rowClass(item) {
    return ''
  }

  //-------------------------------------------------------------------------
  cellClick(key, item, event) {
    return ''
  }

  //-------------------------------------------------------------------------
  defaultTableSettings() {
    return {}
  }
}
