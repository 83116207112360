import {LitElement, html, css} from 'lit';
import './users-table.component';

export class UsersIndex extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 29px;
      margin-bottom: 32px;
    }
    mss-label-value-box {
      max-width: 345px;
      min-width: 170px;
      flex: 1;
      margin-bottom: 18px;
    }

    mss-label-value-box [slot=label] {
      font-size: 18px;
    }
    mss-label-value-box [slot=value] {
      font-size: 24px;
      font-weight: normal;
    }

    mss-label-value-box [slot=label] svg {
      margin-right: 11px;
    }

    mss-label-value-box.groups {
      cursor: pointer;
    }
    mss-label-value-box.groups [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }

    mss-label-value-box.locations {
      cursor: default;
    }
    mss-label-value-box.locations [slot=label] {
      background-color: #438BDF;
      color: white;
    }
    mss-label-value-box.locations [slot=value] {
      background-color: #1B6BC8;
      border-left: 0;
      color: white;
    }

    mss-label-value-box.users {
      cursor: pointer;
    }
    mss-label-value-box.users [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }
  `;

  static properties = {
    data: {state: true},
    missingImageUrl: {},
    orgid: {},
    accountid: {},
    locationid: {},
    locationadmin: {},
    attrid: {}
  };


  constructor() {
    super()
    this.data = {}
    this.table = 'users-table'
  }


  connectedCallback() {
    super.connectedCallback()
  }

  render() {
    return html`
        <users-table missingImageUrl=${this.missingImageUrl} orgid="${this.orgid}" accountid="${this.accountid}" attrid="${this.attrid}"
          locationid="${this.locationid}" locationadmin="${this.locationadmin != undefined}"></users-table>
    `;
  }

}
customElements.define('users-index', UsersIndex)


