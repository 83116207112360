import {html, css} from 'lit'
import {TableBase} from 'shared/tables/table-base.component'
import {graphQL} from "shared/graphQL"
import {Assets} from 'shared/assets'
import 'shared/foundation/mss-favorite-toggle.component'
import 'shared/foundation/mss-folder-icon.component'
import 'shared/tables/table-filter-player-types.component'
import 'shared/tables/table-filter-player-on-off-line.component'
import 'shared/tables/table-filter-player-issue-flags.component'
import 'shared/tables/table-filter-tags.component'
import 'shared/foundation/mss-pill.component'
import 'shared/foundation/mss-dot.component'
import {formatDateTime, timeAgo} from 'shared/dateHelpers'
import {numberToHumanSize} from 'shared/numberHelpers'
import {bulkActionClick} from 'shared/bulkActionHelpers'
import {GetPlayerIssueFlagInfo, PlayerIssueFlagsInfo} from "./player-issue-flag.component";
import {PlayerMoveDialog} from "./player-move-dialog.component"
import {Dialog} from 'shared/dialog-box.component'


class PlayersFolderlessTable extends TableBase {

    static styles = [
        TableBase.styles,
        css`
    .table-base {
      background-color: #FFFFFF;
    }
    .c-column[data-type=icon] img {
      width: 36px;
    }
    .c-column[data-type=inheriting] {
      max-width: 129px;
    }
    .c-column[data-type=issue_flags] {
      max-width: 90px;
    }
    .c-column[data-type=issue_flags] player-issue-flag {
      width: 20px;
      height: 20px;
    }
    .c-column[data-type=player_status] {
      max-width: 145px;
    }
    .c-column[data-type=player_status] mss-dot.online {
      background-color: var(--success-color);
    }
    .c-column[data-type=player_status] mss-dot.offline {
      background-color: var(--danger-color);
    }
    .c-column[data-type=player_status] mss-dot.total {
      background-color: #323851;
   }
    .c-column[data-type=player_status] mss-dot.folder {
      color: #FFFFFF;
      min-width: 30px;
      width: unset;
      padding-left: 2px;
      padding-right: 2px;
      height: 30px;
      font-size: 13px;
   }

    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }
    `,
    ];

    static properties = {
        player_group_id: {type: String},
        of_type: {type: String},
        of_subtype: {type: String},
    };

    constructor() {
        super()
        this.player_group_id = null
        this.of_type = null
        this.of_subtype = null
        this.route = { folder: '/system/players?player_group_id=:id', item: '/players' } // add /:id to go to specific player

        this.dataName = 'Items'
        this.columns = [
            {key: 'icon', title: '', display: 'left'},
            {key: 'bulk_action', title: '', display: 'always-left'},
            {key: 'name', title: 'Name', sortable: 'orderByName'},
            {key: 'description', title: 'Description', sortable: 'orderByDescription'},
            {key: 'serial_number', title: 'Serial Number', sortable: 'orderBySerialNumber'},
            {key: 'inheriting', title: 'Inheriting', sortable: 'orderByInheriting'},
            {key: 'schedule', title: 'Schedule', sortable: 'orderByPlayingScheduleName'},
            {key: 'site_id', title: 'Site ID', sortable: 'orderBySiteId'},
            {key: 'timezone', title: 'Time Zone', sortable: 'orderByTimezone'},
            {key: 'ip_address', title: 'IP Address', sortable: 'orderByIpAddress'},
            {key: 'tags', title: 'Tags'},
            {key: 'nepid', title: 'SAN', sortable: 'orderByNepid'},
            {key: 'media_signage_software', title: 'Player Version', sortable: 'orderByMediaSignageSoftware'},
            {key: 'os_firmware', title: 'OS/Firmware Version', sortable: 'orderByOsFirmware'},
            {key: 'last_heartbeat_at', title: 'Last Heartbeat', sortable: 'orderByLastHeartbeatAt'},
            {key: 'storage_used', title: 'Storage', sortable: ''},
            {key: 'issue_flags', title: 'Flags'},
            {key: 'player_status', title: 'Status', sortable: 'orderByOnline'},
            {key: 'download_percentage', title: 'Download Percentage'},
            {key: 'organization', title: 'Organization', sortable: 'orderByOrganization'},
            {key: 'account', title: 'Account', sortable: 'orderByAccount'},
            {key: 'actions', title: '', display: 'always-right'},
        ]

        this.orderBy = 'orderByName'   // default
        this.orderType = 'ascending'   // default

        this.paginationEnabled = true
        this.paginationOptions.dataName = this.dataName
        this.searchEnabled = true
        this.filterSections = html`
            <table-filter-player-types></table-filter-player-types>
            <table-filter-player-on-off-line></table-filter-player-on-off-line>
            <table-filter-player-issue-flags></table-filter-player-issue-flags>
            <table-filter-tags></table-filter-tags>
        `;
        this.settingsEnabled = true

        // For now, using old bulk action code
        this.bulkActionsMenu = html`
            <div class="action move" @click="${this._move}">
                ${Assets.inlineSvg('subnavigation/move.svg')} Move
            </div>
            <div class="action delete" @click="${this._delete}">
                ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
            </div>
        `;
    }

    _move(e) {
        let dialog
        let textContent = 'Move selected players to '
        if (e.data && e.data.thiz && e.data.playerId) {
            dialog = e.data.thiz.renderRoot.querySelector('mss-player-move-dialog');
            dialog.playerIds = [e.data.playerId]
            textContent = 'Move player to '
        } else {
            dialog = this.renderRoot.querySelector('mss-player-move-dialog');
            dialog.playerIds = this.findIds();
        }
        $('.fow').show();
        $('html').scrollTop(0);
        $(dialog.shadowRoot).find('span')[0].textContent = textContent
        dialog.display = true;
    }

    _delete(e) {
        let ids
        if (e.data && e.data.thiz && e.data.playerId) {
            ids = [e.data.playerId]
            Dialog.open(`
      <div slot="message">Are you sure you want to delete this record?</div>
      <mss-button slot="button" class="primary" value="Yes">Yes</mss-button>
      <mss-button slot="button" class="secondary" value="No">No</mss-button>
    `, (value) => {
                if (value === 'Yes') {
                    $.ajax({
                        type: 'DELETE',
                        url: 'players',
                        data: `id[]=${ids}`,
                        success: null
                    });
                } else {
                    return
                }
            })
        } else {
            ids = this.findIds().join('&id[]=')
            $.ajax({
                type: 'DELETE',
                url: 'players',
                data: `id[]=${ids}`,
                success: null
            });
        }
    }

    findIds() {
        let ids = [];
        let players = this.shadowRoot.querySelectorAll('mss-checkbox');
        for (const player of players) {
            if (player.checked) {
                ids.push(player.value);
            }
        }
        return ids;
    }

    errorHandler(errors) {
        console.log('Folderless table errors');
    }

    getData() {
        let query = `
      query players($filters: PlayerFilter!, $total_count_filters: PlayerFilter!)
      {
        playerCount(filters: $total_count_filters)
        players(filters: $filters) {
          ... on Player {
            account {
              id
              name
            }
            organization {
              id
              name
            }
            id
            name
            issueFlags
            operatingSystem
            description
            serialNumber
            playingSchedule {
              name
            }
            siteId
            timezone
            ipAddress
            tags {
              name
            }
            parentType {
              id
              name
              image
            }
            subType {
              id
              name
            }
            online
            nepid
            mediaSignageSoftware
            osFirmware
            lastHeartbeatAt
            usedStorageBytes
            downloadPercentage
            playerSetting {
              id
            }
          }
        }
      }
    `;

        let variables = {
            filters: {
                pager: {
                    pageSize: this.pageSize,
                    page: this.page - 1,
                },
            },
            total_count_filters: {}
        };

        if (this.orderBy && this.orderType) {
            let val = {}
            val[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
            variables.filters.sort = val
        }
        if (this.searchValue) {
            variables.filters['recordLike'] = this.searchValue
        }

        let filtering = {}

        if (graphQL.accountId()) filtering.forAccountId = graphQL.accountId()
        if (graphQL.organizationId()) filtering.forOrganizationId = graphQL.organizationId()
        if (this.of_type) filtering.ofTypeFamily = this.of_type
        if (this.of_subtype) filtering.ofType = this.of_subtype
        //if (this.player_group_id)  filtering.playerFolderId = this.player_group_id

        let values
        if (this.filterValues) {
            if ((values = this.filterValues["table-filter-player-types"])) {
                filtering.ofAnyType = values
            }

            if ((values = this.filterValues["table-filter-player-on-off-line"])) {
                let online = values.online
                let offline = values.offline
                if (online && !offline) {
                    filtering.online = true
                } else if (!online && offline) {
                    filtering.online = false
                }
            }

            if ((values = this.filterValues["table-filter-player-issue-flags"])) {
                filtering.hasAnyIssueFlags = values
            }

            if ((values = this.filterValues["table-filter-tags"])) {
                filtering.hasAnyTags = values
            }
        }

        // Put filtering in both 'filters' and 'total_count_filters'
        for (const filter in filtering) {
            variables.filters[filter] = filtering[filter]
            variables.total_count_filters[filter] = filtering[filter]
        }

        graphQL.query({
            query: query,
            variables: variables,
            success: (response) => {
                if (response.errors) {
                    this.data = []
                    errorHandler(responese.errors)
                }
                this.totalCount = response.data.playerCount
                this.data = response.data.players
            },
            error: (message) => {
                this.data = []
            },
        });
    }

    cellValue(colKey, item) {
        let value = html``;


        if (colKey === 'bulk_action') {
            if (this.rowIsDisabled(item)) {
                value = html``
            } else {
                value = html`
                    <mss-checkbox name="Player" value="${item.id}"></mss-checkbox>`
            }

        } else if (colKey === 'favorite') {
            if (this.rowIsDisabled(item)) {
                value = html``
            } else {
                let itemType = item.recordType || ''
                let itemId = item.id || ''
                value = html`
                    <mss-favorite-toggle .itemType=${itemType} .itemId=${itemId}></mss-favorite-toggle>`
            }

        } else if (colKey === 'icon') {
            if (this.rowIsDisabled(item)) {
                value = html`deleting`
            } else {
                if (item.parentType) {
                    value = html`<img src="${item?.parentType?.image}"></img>`
                }
            }
        } else if (colKey === 'name') {
            value = item?.name

        } else if (colKey === 'description') {
            value = item?.description

        } else if (colKey === 'serial_number') {
            value = item?.serial_number

        } else if (colKey === 'inheriting') {
            value = `${item?.playerSetting?.inheriting ? 'Yes' : 'No'}`

        } else if (colKey === 'schedule') {
            value = (item?.playingSchedule && item?.playingSchedule?.name) || ''

        } else if (colKey === 'issue_flags') {
            let info = GetPlayerIssueFlagInfo(item.issueFlags[0])
            if (info) {
                value = html`
                    <player-issue-flag issue="${info.tag}"
                                       title="${info.description}"></player-issue-flag>`
            }
        } else if (colKey === 'player_status') {
            value = html`
                <mss-dot class="${item.online ? 'online' : 'offline'}"></mss-dot>&nbsp;&nbsp;
                ${timeAgo(item.lastHeartbeatAt, true)}`
        } else if (colKey === 'download_percentage') {
            if (item.downloadPercentage === 0.0 || item.downloadPercentage) {
                value = html`${Math.floor(item.downloadPercentage * 100)} %`
            } else {
                value = html``
            }

        } else if (colKey === 'site_id') {
            value = item?.siteId
        } else if (colKey === 'organization') {
            value = item.organization?.name
        } else if (colKey === 'account') {
            value = item.account?.name
        } else if (colKey === 'timezone') {
            value = item?.timezone

        } else if (colKey === 'ip_address') {
            value = item?.ipAddress

        } else if (colKey === 'tags') {
            let list = []
            let tagNames = []
            if(item.inheritedTags){
              for (const tag of item.inheritedTags) {
                list.push(html`<focus-color-pill>${tag.name}</mss-pill>`)
                tagNames.push(tag.name)
              }
            }
            if (item.tags) {
              for (const tag of item.tags) {
                list.push(html`<base-color-pill>${tag.name}</mss-pill>`)
                tagNames.push(tag.name)
              }
            }
            value = { data: list, title: tagNames.join(', ')}

        } else if (colKey === 'nepid') {
            value = item?.nepid

        } else if (colKey === 'media_signage_software') {
            value = item?.mediaSignageSoftware

        } else if (colKey === 'os_firmware') {
            value = item?.osFirmware

        } else if (colKey === 'last_heartbeat_at') {
            value = formatDateTime(item?.lastHeartbeatAt)

        } else if (colKey === 'storage_used') {
            value = html`${numberToHumanSize(item?.usedStorageBytes)}`

        } else if (colKey === 'actions') {
            value = [
                {
                    title: 'Move',
                    html: `<div class="item action move">Move</div>`,
                    action: this._move,
                    thiz: this,
                    playerId: item.id
                },
                {
                    title: 'Delete',
                    html: `<div class="item action move">Delete</div>`,
                    action: this._delete,
                    thiz: this,
                    playerId: item.id
                }
            ]
        }
        return value;
    }


    rowIsDisabled(item) {
        return item['deleting']
    }


    rowClass(item) {
        if (this.rowIsDisabled(item)) {
            return 'disable-row'
        }
        return ''
    }


    cellClick(key, item, event) {
        if (key !== 'bulk_action' && key !== 'favorite' && key !== 'actions') {
            //window.location.href = Routes.player_path(item.id)
        }
    }


    defaultTableSettings() {
        return {
            desktop: {
                visibleColumns: [
                    'icon',
                    'name',
                    'description',
                    'organization',
                    'account',
                    'inheriting',
                    'schedule',
                    'issue_flags',
                    'player_status',
                ]
            },
            tablet: {
                visibleColumns: [
                    'icon',
                    'name',
                    'schedule',
                    'issue_flags',
                    'player_status',
                ]
            },
            mobile: {
                visibleColumns: [
                    'icon',
                    'name',
                    'player_status',
                ]
            },
        }
    }
}

customElements.define('players-folderless-table', PlayersFolderlessTable)
