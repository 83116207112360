import {html, css, LitElement} from 'lit'

class DisplayMappings extends LitElement {
    static properties = {
        field_type: {type: String},
    };

    static styles = [
        css`
            .mappings {
                font-size: 12px;
                width: 320px;
            }
            .mappings label {
                line-height: 18px;
                font-weight: bold;
                color: #5B5B5B;
                display: inline-block;
            }
            .mappings div {
                font-weight: 400;
                font-size: 16px;
                line-height: 22.4px;
                color: #5B5B5B;
                word-wrap: break-word;
                margin-bottom: 20px;
            }
            .mappings .nokey {
                margin-bottom: 0px;
            }
        `
    ];

    constructor() {
        super()
    }

    render() {
        const fields = Object.entries(JSON.parse(this.fields));
        if(this.value != 'Field Mapping') {
            return html`
                <div class="mappings">
                    ${html`
                        ${fields.map(([key, value]) => html`
                            <label>${key}</label>
                            <div title="${key === 'FTP Password' ? '*****' : value}">${key === 'FTP Password' ? '*****': value}</div>
                        `)}
                    `}
                </div>
            `;
        }
        return html`
            <div class="mappings">
                ${html`
                    <label>Field Mapping</label><div class="nokey"></div>
                    ${fields.sort().map(([key, value]) => html`
                        <div>
                            ${key} ....
                            ${key === 'FTP Password' ? '*****': this.readable(value)}
                        </div>
                    `)}
                `}
            </div>
        `;
    }

    readable(str) {
        const mapped_strings = {
            "first_name": "First Name",
            "last_name": "Last Name",
            "preferred_name": "Preferred Name",
            "birth_day": "Birth Day",
            "birth_month": "Birth Month",
            "work_start": "Work Start Date",
            "position": "Position",
            "email": "Email",
            "customer_uid": "Unique ID",
            "custom_attributes": "Attributes",
            "groups": "Group(s)",
            "locations": "Location(s)",
            "opt_out_all": "Opt Out All",
            "opt_out_birthday": "Opt Out Birthday",
            "opt_out_work_anniversary": "Opt Out Work Anniversary",
            "roles": "Role",
            "military_service": "Military Service",
        };
        return mapped_strings[str];
    }
}



customElements.define('display-mappings', DisplayMappings);