import {html, css} from 'lit'
import {TableBase} from 'shared/tables/table-base.component'
import {graphQLIngestor} from "shared/graphQLIngestor"


class backgroundJobsTable extends TableBase {

    static styles = [
        TableBase.styles,
        css`

    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }
    .danger {
      color: #D75762;
    }

    .c-column span{
      display: inline-flex;
    }

    .fa{
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      cursor: pointer;
    }
    .fa-exclamation-triangle{
      margin-left: 5px;
      margin-top: 3px;
    }
    .fa-exclamation-triangle:before {
        content: "\\f071";
    }
    `,
    ];

    constructor() {
        super()
        this.updatingModal = false
        this.escapeNotInit = true;
        this.route = '/integration_jobs/:id'
        this.dataName = 'processes'
        this.columns = [
            {key: 'id', title: 'Id'},
            {key: 'status', title: 'Status'},
            {key: 'completedAt', title: 'Completed At'},
            {key: 'actions', title: '', display: 'always-right'},
        ]
    }

    connectedCallback() {
        super.connectedCallback()
        this.render();
    }

    cellValue(colKey, item) {
        let value = html``;

        if (colKey === 'id') {
            value = item.id
        } else if (colKey === 'status') {
            value = []
            value.push(html`
                ${item.status}`);
            if (item.hasErrors) {
                value.push(html`<span class="danger fa fa-exclamation-triangle"></span>`)
            }
            return value
        } else if (colKey === 'completedAt') {
            value = Util.formatDateTime(item.completedAt);
        } else if (colKey === 'actions') {
            if (item.status != 'CANCELED' && item.status != 'COMPLETED') {
                value = [
                    {
                        title: 'Cancel process',
                        url: Routes.integration_cancel_job_path(this.id) + "?job_id=" + item.id,
                        httpMethod: 'post',
                        remote: false,
                    }
                ]
            }
        }
        return value
    }


    formatInfo(info, type) {
        let html = '';
        if (info && info.length > 0) {
            for (const inf of info) {
                let batch = false
                let batchHandled = false
                if (inf.idRepresents == 'BATCH') {
                    batch = true;
                }
                for (const msg of inf.messages) {
                    let cssClass = 'info'
                    if (msg.messageLevel == 'ERROR') {
                        cssClass = 'error'
                        if (type != 'info') {
                            if (batch && !batchHandled) {
                                html += `<div class="batch">BATCH: ${inf.itemId}`
                                batchHandled = true
                            }
                            html += `<span class=${cssClass}>${moment(msg.createdAt).format('MM/DD h:mm:ss a')} ${msg.messageLevel}: ${msg.message}</span>`
                        }
                    } else {
                        if (type != 'errors') {
                            if (batch && !batchHandled) {
                                html += `<div class="batch">BATCH: ${inf.itemId}`
                                batchHandled = true
                            }
                            html += `<span class=${cssClass}>${moment(msg.createdAt).format('MM/DD h:mm:ss a')} ${msg.messageLevel}: ${msg.message}</span>`
                        }
                    }
                }
                if (batchHandled) {
                    html += `</div>`
                }
            }
            html += '</div>'
        } else {
            html += '';
        }
        return html;
    }

    openModal(job) {
        let thiz = this;
        let warn = '';
        let error = job.hasErrors
        if (error) {
            warn = `<span class="danger fa fa-exclamation-triangle"></span>`
        }
        let html = `
        <div class="information">
          <div class="detailColumn left">
            <div class="info">
              <div class="label">Name</div>
              <div class="value">${job.name}</div>
            </div>
            <div class="info">
              <div class="label">Status</div>
              <div class="value">${job.status}&nbsp;&nbsp;${warn}</div>
            </div>
            <div class="info">
              <div class="label">Started</div>
              <div class="value">${job.startedAt ? Util.formatDateTime(job.startedAt) : '-'}</div>
            </div>
            <div class="info">
              <div class="label">Job Class</div>
              <div class="value">${job.jobClass}</div>
            </div>
            <div class="info">
              <div class="label">State</div>
              <div class="value">${job.state ? JSON.stringify(job.state) : '-'}</div>
            </div>
          </div>
          <div class="detailColumn middle">
          <div class="info info-right">
              <div class="label">Job ID</div>
              <div class="value">${job.jobId}</div>
            </div>
            <div class="info">
              <div class="label">Percent Complete</div>
              <div class="value">${job.percentComplete ? job.percentComplete : '0'} %</div>
            </div>
            <div class="info">
              <div class="label">Completed</div>
              <div class="value">${job.completedAt ? Util.formatDateTime(job.completedAt) : '-'}</div>
            </div>
            <div class="info">
              <div class="label">Job Queue</div>
              <div class="value">${job.jobQueue ? job.jobQueue : '-'}</div>
            </div>
          </div>
          <div class="detailColumn right">
            <div class="info info-right">
                <div class="refresh-modal"></div>
            </div>
            <div class="info info-right">
              <div class="label">&nbsp;</div>
              <div class="value no-borders">&nbsp;</div>
            </div>
            <div class="info info-right">
              <div class="label">Duration</div>
              <div class="value">${job.completedAt ? Util.formatDuration(job.startedAt, job.completedAt) : '-'}</div>
            </div>
          </div>
        </div>
        <mss-button slot="button" value="info">Info</mss-button>
        <mss-button slot="button" value="errors">Errors</mss-button>
        <mss-button slot="button" class="primary" value="all">All</mss-button>
        <div class="integrationLogs">
          ${this.formatInfo(job.logs, 'all')}
        </div>
      `;
        DialogModal.render({
            title: 'Background Job Details',
            message: html,
            class: 'background-details',
        });
        $('mss-button').click(({thiz: thiz, job: job}), this.filterLogs)

        $('.refresh-modal').click(({thiz: thiz, job: job}), this.refreshModal)
    }


    filterLogs(e) {
        let value = e.target.getAttribute('value')
        $(e.target).siblings('mss-button').removeClass('primary')
        $(e.target).addClass('primary')
        $('.integrationLogs').html(e.data.thiz.formatInfo(e.data.job.logs, value))
    }

    updated() {
        let rows = this.renderRoot.querySelectorAll('.c-row')
        let thiz = this
        for (const row of rows) {
            row.addEventListener('click', this.rowClick)
            row.backgroundJobsTable = thiz;
        }
    }

    rowClick(event, options) {
        if (event.target.__actions) {
            return;
        }
        event.preventDefault();
        let id = this.dataset['id']
        this.backgroundJobsTable.getData(id);
    }

    refreshModal = function (e) {
        DialogModal.close();
        e.data.thiz.getData(e.data.job.id)
    }

    getData(id) {
        if (id) {
            if (this.updatingModal) { //throttle
                return false;
            }
            this.updatingModal = true;
        }

        let thiz = this
        let query = `
         query Integration($id: ID!, $backgroundJobFilter: BackgroundJobFilter){
            integration(id: $id){
              backgroundJobs(filter: $backgroundJobFilter){
                id
                status
                completedAt
                hasErrors
              }
            }
         }
        `;

        let variables = {
            "id": this.id,
            "backgroundJobFilter": {
                "pager": {
                    "page": 0,
                    "pageSize": 10
                }
            }
        }
        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                if (response.data) {
                    let newData = response.data.integration.backgroundJobs
                    let reRender = false
                    if (id && newData && thiz.data) {
                        reRender = JSON.stringify(newData) == JSON.stringify(thiz.data)
                    }
                    this.data = newData
                    if (id) {
                        let job = newData.filter(x => x.id === id)[0]
                        if (job) {
                            this.getBackgroundJob(job.id)
                        }
                        if (reRender) {
                            this.render()
                        }
                        this.updatingModal = false;
                    }
                }
            },
            error: (message) => {
                if (id) {
                    this.updatingModal = false;
                }
                this.data = []
                console.log('GraphQLIngestor Errors: ', message)
            },
        });
    }

    getBackgroundJob(id) {
        let query = `
         query BackgroundJob($id: ID!){
            backgroundJob(id: $id){
                id
                status
                completedAt
                hasErrors
                createdAt
                jobClass
                jobId
                jobQueue
                name
                percentComplete
                state
                startedAt
                updatedAt
                logs{
                    idRepresents,
                    itemId,
                    messages{
                      message,
                      messageLevel,
                      createdAt
                     },
                 }
            }
         }
        `;

        let variables = {
            "id": id
        }

        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                if (response.data) {
                    let job = response.data.backgroundJob
                    if (job.logs.length > 0) {
                        let lastEl = job.logs.last()
                        if (lastEl.itemId == null) {
                            job.logs.pop()
                            job.logs.unshift(lastEl)
                        }
                    }
                    this.openModal(job)
                    if (this.escapeNotInit) {
                        $(document).on('keydown', function (event) {
                            if (event.key == "Escape") {
                                DialogModal.close();
                            }
                        });
                        this.escapeNotInit = false;
                    }
                    this.getData()
                    this.render()
                }
            },
            error: (message) => {
                console.log('GraphQLIngestor Errors: ', message)
            },
        });

    }
}

customElements.define('background-jobs-table', backgroundJobsTable)

