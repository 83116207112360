import {css, html, LitElement} from "lit"


class TableBulkActions extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    
    :host {
      position: relative;
      margin: 0 6px;
      color: white;
    }
    
    .select-all, .deselect-all {
      color: #438BDF;
      cursor: pointer;
    }
    .select-all:hover, .deselect-all:hover {
      color: #336fa9;
    }
    .deselect-all {
      display: none;
      margin-left: 9px;
    }
    :host([open]) .deselect-all {
      display: inline-block;
    }
    
    .bulk-action-menu {
      display: flex;
      position: fixed;
      top: 62px;
      right: 382px;
      z-index: 50;
      background-color: #2E5E99;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 0px;
      transition: height .2s;
      overflow: hidden;
    }
    :host([open]) .bulk-action-menu {
      height: 35px;
    }
    
    ::slotted(*) {
      height: 27px;
      padding: 0px 30px;
      color: white;
      margin: 4px;
      display: flex;
      align-items: center;
      transition: color .2s;
      cursor: pointer;
    }
  `;

  static properties = {
    actions: {state: true},
    open: {type: Boolean, reflect: true},
  };


  constructor() {
    super()
    this.open = false
    this.actions = []
  }


  render() {
    return html`
        <span class="select-all" @click=${this.selectAll}>Select All</span>
        <span class="deselect-all" @click=${this.deselectAll}>Deselect All</span>
        <div class="bulk-action-menu">
            <slot></slot>
        </div>
    `;
  }

  disableLinks(item) {
    item.css('pointer-events', 'none');
  }

  enableLinks(item) {
    item.css('pointer-events', 'auto');
  }

  showMenu(item) {
    item.css('filter', 'brightness(100%)')
  }

  hideMenu(item) {
    item.css('filter', 'brightness(180%)')
  }

  updated() {
    let items = [$("li[data-tooltip-text='Edit Group'] a span"),
      $("li[data-tooltip-text='Edit Group'] a .icon"),
      $("li[data-tooltip-text='Group Tags'] a span"),
      $("li[data-tooltip-text='Group Tags'] a .icon"),
      $("li[data-tooltip-text='Group Settings'] a span"),
      $("li[data-tooltip-text='Group Settings'] a .icon"),
      $("li[data-tooltip-text='Reboot Group'] a span"),
      $("li[data-tooltip-text='Reboot Group'] a .icon"),
      $("li[data-tooltip-text='Delete Group'] a span"),
      $("li[data-tooltip-text='Delete Group'] a .icon")
    ]
    let links = [$("li[data-tooltip-text='Edit Group'] a"),
      $("li[data-tooltip-text='Group Tags'] a"),
      $("li[data-tooltip-text='Group Settings'] a"),
      $("li[data-tooltip-text='Reboot Group'] a"),
      $("li[data-tooltip-text='Delete Group'] a")
    ]
    let thiz = this
    if (thiz.open) {
      for (const item of items) {
        thiz.hideMenu(item)
      }
      for (const item of links) {
        thiz.disableLinks(item)
      }
    } else {
      for (const item of items) {
        thiz.showMenu(item)
      }
      for (const item of links) {
        thiz.enableLinks(item)
      }
    }
  }


  selectAll(event) {
    this.dispatchEvent(new Event('selectAll', {bubbles: true, composed: true}))
    this.open = true
  }


  deselectAll(event) {
    this.dispatchEvent(new Event('deselectAll', {bubbles: true, composed: true}))
    this.open = false
  }
}

customElements.define('table-bulk-actions', TableBulkActions)