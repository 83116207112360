
import {LitElement, html, css} from 'lit'
import {graphQL} from '../shared/graphQL'
import {Assets} from 'shared/assets'
import 'shared/foundation/mss-label-value-box.component'
import 'screenshots/smart-scan-table.component'
import 'screenshots/smart-scan-slideshow.component'


class SmartScanDetails extends LitElement {
  static styles = css`
    smart-scan-summary {
      margin-top: 36px;
      margin-bottom: 36px;
    }

    .stat-bar {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;
    }
    mss-label-value-box {
      height: 40px;
      min-width: 295px;
      cursor: pointer;
    }
    .stat-bar [slot=label] svg {
      display: inline-block;
      position: relative;
      top: 0px;
      left: -5px;
      margin-right: 1px;
      height: 40px;
      width: 40px;
      font-size: 16px;
    }
    .stat-bar [slot=value] {
      font-weight: bold;
      font-size: 20px;
    }
  `;


  static properties = {
    data: {state: true},
  };


  constructor() {
    super()
    this.data = {}
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData(value) {
    let graphqlQuery = `
      query screenshots(
        $normal: ScreenshotFilter,
        $broken: ScreenshotFilter,
        $semi_broken: ScreenshotFilter,
        ) {

        totalCount: screenshotCount
        normalCount: screenshotCount(filters: $normal)
        brokenCount: screenshotCount(filters: $broken)
        semiBrokenCount: screenshotCount(filters: $semi_broken)
      }
    `;

    let graphqlVariables = {
      normal: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "NORMAL",
        newestRecordsOnly: true
      },
      broken: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "BROKEN",
        newestRecordsOnly: true
      },
      semi_broken: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "SEMI_BROKEN",
        newestRecordsOnly: true
      },
    };

    graphQL.query({
      query: graphqlQuery,
      variables: graphqlVariables,
      success: (response) => {
        this.data = response.data
      },
      error: (message) => {
        console.log(`Error getting screenshots summary counts: ${message}`)
      },
    });
  }


  render() {
    return html`
      <smart-scan-summary></smart-scan-summary>

      <div class="stat-bar">
        <mss-label-value-box value="BROKEN">
          <span slot="label" class="broken">${Assets.inlineSvg('screenshot/icon-broken.svg')}Error</span>
          <span slot="value" class="value broken-value">${this.data.brokenCount}</span>
        </mss-label-value-box>
        <mss-label-value-box value="SEMI_BROKEN">
          <span slot="label" class="broken">${Assets.inlineSvg('screenshot/icon-semi-broken.svg')}Warning</span>
          <span slot="value" class="value broken-value">${this.data.semiBrokenCount}</span>
        </mss-label-value-box>
        <mss-label-value-box value="NORMAL">
          <span slot="label" class="broken">${Assets.inlineSvg('screenshot/icon-good.svg')}Good</span>
          <span slot="value" class="value broken-value">${this.data.normalCount}</span>
        </mss-label-value-box>
      </div>

      <div style="width: 100%">
        <smart-scan-table></smart-scan-table>
      </div>
    `;
  }
}
customElements.define('smart-scan-details', SmartScanDetails);
