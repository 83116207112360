import {html, css, LitElement} from 'lit';
import {templateStyles} from './template-styles';
import {graphQL} from "../../shared/graphQL";

export class ContentTemplatePreview extends LitElement {
  static styles = [templateStyles, css`
      :host {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          text-align: center;
          justify-content: center;
      }

      .container {
          width: 80%;
          padding-top: 1em;
          padding-bottom: 2em;
      }

      .row {
          justify-content: space-between;
          margin-bottom: .5em;
          font-size: 14px;
          font-weight: 100;
          color: #666666;
          line-height: 17px;
      }

      #preview-content img {
          object-fit: contain;
          max-height: 500px;
          max-width: 100%;
      }

      .btn-sm {
          width: 10%;
      }

      #previewActionPrevious, #previewActionNext {
          cursor: pointer;
          position: absolute;
          top: 50%;
          font-size: 30px;
          color: #306BC3;
          background-color: white;
          border-radius: 50px;
          height: 60px;
          width: 60px;
          border: solid 1px #C1C1C1;
          top: calc(50% + 10px);
      }

      .fa, .fas {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
      }

      .fa-chevron-left, .fa-chevron-right {
          position: relative;
          top: 15px;
      }

      .fa-chevron-left:before {
          content: "\\f053";
      }

      .fa-chevron-right:before {
          content: "\\f054";
      }

      #previewActionPrevious {
          left: 18px;
      }

      .hidden {
          display: none;
      }

      #previewActionPrevious .disabled {
          background-color: #EDEDED;
          color: #B8C7E2;
      }

      #previewActionNext {
          right: 18px;
      }

      #previewActionNext .disabled {
          background-color: #EDEDED;
          color: #B8C7E2;
      }
  `];

  static properties = {
    currentLocationData: Object,
    location: Object,
    previewData: {type: Object, state: true},
    image: {type: Object, state: true}
  };

  constructor() {
    super();
    this.pageSize = 10;
    this.currentPage = 0;
    this.orderBy = 'orderByFullName';
    this.orderType = 'ASC';
    this.filtersData = []
    this.sortingData = ''
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('close_preview', this._closePreview);
    window.addEventListener('refresh-preview', this.updatePreview);
    window.addEventListener('get-data', this.refreshData);
    this.refreshData();
  }

  disconnectedCallback() {
    window.removeEventListener('close_preview', this._closePreview);
    window.removeEventListener('refresh-preview', this.updatePreview);
    window.removeEventListener('get-data', this.refreshData);
    super.disconnectedCallback();
  }

  _getQuerySort() {
    let sort = {};
    sort[this.orderBy] = this.orderType;
    return sort;
  }

  _getQueryPage() {
    return {
      pageSize: this.pageSize, page: this.currentPage,
    };
  }

  getFiltersData() {
    const els = document.querySelectorAll('.selected-filters .selected-filter');
    if (!els.length || (els.length === 1 && !els[0].dataset['filter'])) {
      return [];
    }
    return [...els].map(x => JSON.parse(x.dataset['filter']))
  }


  getSortingData() {
    let sorting = document.getElementById('sorting');
    let orderSelect = document.getElementById('order');
    let sortingData = {sortable_field_id: '', sort: ''}
    if (sorting.selectedIndex != undefined && sorting.selectedIndex != 0) {
      sortingData['sortable_field_id'] = sorting.options[sorting.selectedIndex].value
      sortingData['sort'] = orderSelect.options[orderSelect.selectedIndex].value
    }

    return sortingData
  }

  firstUpdated() {
    super.firstUpdated();
    let previewActionNext = $(this.shadowRoot).find('#previewActionNext')
    let previewActionPrevious = $(this.shadowRoot).find('#previewActionPrevious')
    let thiz = this

    $(previewActionNext).on('click', function () {
      if (previewActionNext.hasClass('disabled')) {
        return;
      }
      $(previewActionNext).addClass('disabled');
      thiz.previewData.setPage(thiz.previewData.getCurrentPage() + 1, true, true);
    });

    $(previewActionPrevious).on('click', function () {
      if (previewActionPrevious.hasClass('disabled')) {
        return;
      }
      $(previewActionPrevious).addClass('disabled');
      thiz.previewData.setPage(thiz.previewData.getCurrentPage() - 1, true, true);
    });
  }

  render() {
    return html`
        <div class="container">
            <div id="previewActionPrevious" class="hidden disabled">
                <i class="fas fa-chevron-left previous"></i>
            </div>
            <div id="previewActionNext" class="hidden">
                <i class="fas fa-chevron-right next"></i>
            </div>
            <div class="row">
                <div>Image Dimensions: ${this.image ? this._getImageDimensions() : html`Loading...`}</div>
                <div>Image Size: ${this.image ? this._getFileSize() : html`Loading...`}</div>
                ${this.currentLocationData ? '' : html`
                    <button
                            class="primary btn-sm"
                            @click=${this._editLocation}
                    >
                        ${this.location ? this.location.name : ''}
                    </button>`}
            </div>
            <div id="preview-content">
                <img src="${this.image}"/>
            </div>
        </div>`;
  }

  async getData() {
    try {
      this.previewData = {...window.MMeditor};
      const response = await this.getUsersForLocation();
      await this._setPreviewUsers(response);
    } catch (reject) {
      this._setPreviewUsers([]);
      this.error = reject;
    }
  }

  refreshData = () => {
    this.filtersData = JSON.stringify(this.getFiltersData());
    this.sortingData = this.getSortingData();
    this.getData();
  }

  updatePreview = () => {
    this.image = this.previewData.getImage();
    let previewActionPrevious = $(this.shadowRoot).find('#previewActionPrevious')
    let previewActionNext = $(this.shadowRoot).find('#previewActionNext')
    if (this.previewData.getPages() > 1) {
      previewActionPrevious.removeClass('hidden')
      previewActionNext.removeClass('hidden')
      if (this.previewData.getCurrentPage() == 0) {
        previewActionPrevious.addClass('disabled')
      } else {
        previewActionPrevious.removeClass('disabled')
      }
      if (this.previewData.getCurrentPage() + 1 == this.previewData.getPages()) {
        previewActionNext.addClass('disabled')
      } else {
        previewActionNext.removeClass('disabled')
      }
    } else {
      previewActionPrevious.addClass('hidden')
      previewActionNext.addClass('hidden')
    }
  }

  getUsersForLocation() {
    const locationId = this.currentLocationData ? this.currentLocationData.id : this.location.id;

    return new Promise((resolve, reject) => {
      $.ajax({
        type: "get",
        url: Routes.users_dynamic_preview_path(),
        data: {
          location_id: locationId,
          filters: this.filtersData,
          sortable_field_id: this.sortingData.sortable_field_id,
          direction: this.sortingData.sort
        },
        success: (response) => resolve(response),
        error: (message) => reject(message),
      })
    });
  }

  _getImageDimensions() {
    const imageSize = this.previewData.getImageSize();
    return html
      `${imageSize.width} x ${imageSize.height} px`
      ;
  }

  _getFileSize() {
    const head = 'data:image/png;base64,';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const bytes = Math.round((this.image.length - head.length) * 3 / 4);

    if (bytes == 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  _setPreviewUsers(users) {
    if (users) {
      // TODO anything else to set here?
      this.previewData.setPlaceholderProfiles(users, true, true);
    }
  }

  _closePreview = () => {
    window.setTimeout(function () {
      window.dispatchEvent(new Event("filter-select-changed"));
    }, 200)
  }

  _editLocation() {
    this.previewData.setPage(0);
    this.dispatchEvent(new CustomEvent('update-location', {
      detail: {location: this.location, restart: true}, bubbles: true, composed: true
    }));
  }
}

customElements.define('content-template-preview', ContentTemplatePreview);
